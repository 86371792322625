import React from "react";
import ReactDOM from "react-dom";
import App from "./AppRouter.jsx";

import { Provider } from "react-redux";
import { store } from "./Redux/store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "./index.scss";
import "./fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

let baseTheme = createMuiTheme();
baseTheme = responsiveFontSizes(baseTheme);

Sentry.init({
  dsn: "https://665a813f015b4d919e92365dd5f7c6cf@o877499.ingest.sentry.io/6238621",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: "production",
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={baseTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
